<template>
  <div class="all">
    <img src="../assets/logo.png" alt="" class="img mr-3" />
     <b-alert class="alert" v-if="loginError" variant="danger" disimissable show="5"
          >Login failed : {{ errorMessage }}</b-alert
        >
     
    <div class="box">
      <b-form @submit="onSubmit">
        <p class="title">Welcome Back</p>
        <p class="hint mt-2">Enter your credentials to access your account.</p>   
        <div class="mt-5">
          <b-form-input
            v-model="form.email"
            type="email" size="lg"
            placeholder="Enter your email"
            class="shape email"
          ></b-form-input>

          <b-input-group size="lg" class="shape mt-4">
            <b-form-input
              v-model="form.password"
              class="password"
              :type="inputType"
              placeholder="Enter your password"
            >
            </b-form-input>
            <b-input-group-append is-text>
              <b-icon
                class="sr"
                v-if="inputType === 'password'"
                icon="eye-slash-fill"
                @click.prevent="switchVisibility"
              ></b-icon>
              <b-icon
                class="sr"
                v-if="inputType === 'text'"
                icon="eye-fill"
                @click.prevent="switchVisibility"
              ></b-icon>
            </b-input-group-append>
          </b-input-group>
        </div>
        <p class="reset"  v-on:click="resetPassword()">Forgot password?</p>
        <b-button variant="primary" type="submit" class="botton"
          >Sign In</b-button
        >
      </b-form>
    </div>
    <div class="hint mt-4">
      <p>
        Do not have an account?
        <span class="register" v-on:click="register()">Register here</span>
      </p>
    </div>
  </div>
</template>

<script>
import api from "../api/index";

export default {
  name: "Login",
  data() {
    return {
      loginError: false,
      errorMessage: "",
      form: {
        email: "",
        password: "",
      },
      logged: false,
      inputType: "password",
    };
  },
  mounted() {
    //this.logged = this.$store.getters.isAuthenticated;
  },
  methods: {
    switchVisibility() {
      this.inputType = this.inputType === "password" ? "text" : "password";
    },
    onSubmit(event) {
      this.loginError = false;
      event.preventDefault();
      api.login(this.form).then(
        (response) => {
          const token = response.responseData;
          this.$store.commit("saveToken", token);
          this.$router.push({ path: "/homepage" });
        },
        (error) => {
          this.loginError = true;
          this.errorMessage = error;
        }
      );
    },
    register() {
      this.$router.push({ path: "/register" });
    },
    resetPassword() {
      this.$router.push({ path: "/forgotpassword" });
    }
  },
};
</script>
<style scoped>
.all {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  background: #f2f6ff;
  text-align: center;
}
.img {
  margin: auto;
  width: 253px;
  height: 75px;
  margin-top: 60px;
}
.alert {
  width: 28%;
  margin: auto;
  margin-top:30px
}
.box {
  margin: auto;
  width: 644px;
  height: 520px;
  margin-top: 35px;
  background: #ffffff;
  border: 1px solid #e1e1e1;
  box-sizing: border-box;
  box-shadow: 4px 4px 15px rgba(225, 225, 225, 0.5);
}
.title {
  margin: auto;
  margin-top: 60px;

  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #23438e;
}
.hint {
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #859ec0;
}
.shape {
  margin: auto;
  width: 492px;
  height: 50px;
  border: 1px solid #e1e1e1;
  border-radius: 5px;
}
.email {
  background-image: url("../assets/Vector.png");
  background-repeat: no-repeat;
  text-indent: 40px;
  background-position: 15px 18px;
}
.password {
  background-image: url("../assets/lock.png");
  background-repeat: no-repeat;
  text-indent: 40px;
  background-position: 15px 16px;
}
.botton {
  margin: auto;
  margin-top: 20px;
  width: 492px;
  height: 56px;
  background: #23438e;
  border-radius: 5px;
}
.reset {
  margin-right: 75px;
  margin-top: 15px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  text-align: right;
  text-decoration-line: underline;

  color: #23438e;
  cursor: pointer;
}
.register {
  font-weight: 600;
  color: #23438e;
  cursor: pointer;
}
</style>
